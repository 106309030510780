import { Box, Typography, useMediaQuery } from '@material-ui/core'
import React, { Dispatch, FC, SetStateAction } from 'react'
import styled from 'styled-components'

import { atMinWidth } from '../../../../styles/helpers'
import { theme } from '../../../../styles/muiThemes'
import { QRCodeBox } from '../QRCodeBox'
import { PaymentData, PaymentQRCodeData } from '../types'
import { TabContentWrapper } from './QRCodeDialog.styles'
import { QRCodeDialogBankDetails } from './QRCodeDialogBankDetails'
import { QRCodeDialogFields } from './QRCodeDialogFields'
import { QRCodeDialogTabSection } from './QRCodeDialogTabSection'

type Props = {
  paymentFieldsData: PaymentData;
  setPaymentFieldsData: Dispatch<SetStateAction<PaymentData>>;
  qrData: PaymentQRCodeData | undefined;
  isAmountEnabled: boolean;
  isLoading: boolean;
};

export const QRCodeDialogQRTabContent: FC<Props> = ({ paymentFieldsData, setPaymentFieldsData, qrData, isAmountEnabled, isLoading }) => {
  const isGtSm = useMediaQuery(theme.breakpoints.up('sm'))

  return (
    <QRContentWrapper>
      <QRCodeDialogTabSection title="Details">
        <QRCodeDialogFields
          paymentData={paymentFieldsData}
          setPaymentData={setPaymentFieldsData}
        />
      </QRCodeDialogTabSection>
      <QRCodeDialogTabSection title="QR code CZK">
        <QRCodeBox
          data={qrData}
          isAmountEnabled={isAmountEnabled}
          size={isGtSm ? 250 : 200}
        />
      </QRCodeDialogTabSection>
      <QRCodeDialogTabSection
        title="Transfer details"
        gridColumn="1 / -1"
      >
        {!isLoading && <QRCodeDialogBankDetails
          bankDetails={{
            'Bank account': '2902246180/2010',
            'Bank': 'FIO',
            'Your pairing code (vs)': qrData?.pairing_code ?? paymentFieldsData.pairingCode.toString(),
          }}
        />}
      </QRCodeDialogTabSection>
    </QRContentWrapper>
  )
}

const QRContentWrapper = styled(TabContentWrapper)`
  display: grid;
  column-gap: 40px;
  row-gap: 32px;

  ${atMinWidth.tablet} {
    grid-template-columns: 1fr auto;
    column-gap: 64px;
  }
`
